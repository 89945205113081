import React, { useEffect, useState } from "react";
import { redirecttoLandingPage } from "../../Constants/constants";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import ExperienceUI from "./ExperienceUI/ExperienceUI";
import Tutorial from "./ExperienceUI/UI_Components/Tutorial/Tutorial";
import Viewer from "./Viewer";

function Experience({ auth }) {
  const [percent, setpercent] = useState(0);
  const [isloading, setisloading] = useState(true);
  const [openModal, setopenModal] = useState(false);
  const [modalContent, setModalContent] = useState(
    <Tutorial
      onClose={() => {
        setopenModal(false);
      }}
    />
  );
  const [modalfullScreeen, setModalfullScreen] = useState(false);
  const [color, setcolor] = useState("#53B0AE");

  useEffect(() => {
    redirecttoLandingPage(auth);
  }, []);
  return (
    <div>
      <Viewer
        setopenModal={setopenModal}
        setModalContent={setModalContent}
        setModalfullScreen={setModalfullScreen}
        setcolor={setcolor}
        setpercent={setpercent}
        setisloading={setisloading}
      />
      <ExperienceUI
        isloading={isloading}
        openModal={openModal}
        setopenModal={setopenModal}
        modalContent={modalContent}
        setModalContent={setModalContent}
        setModalfullScreen={setModalfullScreen}
        setcolor={setcolor}
        modalfullScreeen={modalfullScreeen}
        color={color}
      />
      <LoadingScreen percent={percent} isloading={isloading} />
    </div>
  );
}

export default Experience;
