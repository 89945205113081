import React, { useEffect, useState } from "react";
import "./LoadingScreen.css";
import IAMLogo from "./Assets/IAFLogo.png";
import metaQubeLogo from "./Assets/metaQubeLogo.png";
import XRCLogo from "./Assets/XRLogo.png";
import { FadeElement } from "../../Constants/constants";

function LoadingScreen({ percent, isloading }) {
  useEffect(() => {
    if (!isloading) {
      FadeElement("loadingRoot", 5);
    }
  }, [isloading]);
  return (
    <div id="loadingRoot" className="loading_root">
      <div className="center_container">
        <div
          style={{
            width: "fit-content",
            height: "fit-content",
            justifySelf: "center",
          }}
        >
          <img src={IAMLogo} />
        </div>
        <div id="test" className="loading_Bar">
          <div
            className="loadingBar_filler"
            style={{ width: percent + "%" }}
          ></div>
        </div>
        <text>{percent}%</text>
        <text>Preparing your India Art Fair Experience</text>
      </div>

      <img className="loadingBottom_Logo right_bottom" src={metaQubeLogo} />
      <img
        className="loadingBottom_Logo left_bottom"
        style={{ left: 0 }}
        src={XRCLogo}
      />
    </div>
  );
}

export default LoadingScreen;
