import React from "react";
import "./Tutorial.css";
import click from "./assets/click.png";
import clickDrag from "./assets/clickDrag.png";
import explore from "./assets/Explore.png";
import hotspot from "./assets/Hotspot.png";
import tutorial from "./assets/Tutorial.png";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function Tutorial({ onClose }) {
  return (
    <div className="tutorial_container">
      <div style={{ display: "flex", flexDirection: "column" }}>
        <IconButton style={{ alignSelf: "end" }} onClick={onClose}>
          <CloseIcon style={{ color: "white" }} />
        </IconButton>
        <header>Welcome to India Art Fair</header>
        <h3>How to navigate</h3>
      </div>
      <div className="img_container">
        <div className="block_container">
          <img style={{ width: "80%" }} src={click} />
        </div>
        <div className="block_container">
          <img style={{ width: "80%" }} src={clickDrag} />
        </div>

        <div className="block_container">
          <img style={{ width: "80%" }} src={explore} />
        </div>

        <div className="block_container">
          <img style={{ width: "80%" }} src={hotspot} />
        </div>
        <div className="block_container">
          <img style={{ width: "80%" }} src={tutorial} />
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
