import { Provider } from "react-redux";
import { store } from "./Redux/redux";
import CustomRoutes from "./Routes/CustomRoutes";

function App() {
  return (
    <Provider store={store}>
      <CustomRoutes />
    </Provider>
  );
}

export default App;
