import React, { Component } from "react";
import * as BABYLON from "babylonjs";
import "babylonjs-loaders";

import GUI from "babylonjs-gui";

import mainModel from "../../assets/IAF Mobile Optimized.glb";

import BabylonScene from "./Scene";
import type { SceneEventArgs } from "./Scene";
import { SceneLoader } from "babylonjs";
import { enableNavigation, pointerEvents } from "./Navigation";
import { inputController, keyboardEvents, spawnLocalPlayer } from "./Player";
import { joinRoom, socket } from "./Multiplayer/Network";
import { store } from "../../Redux/redux";
import { createArialCameraAndButtons } from "./ArialView";
import { spawnHotspots } from "./Interaction";

type Props = {
  setopenModal: any,
  setModalContent: any,
  setModalfullScreen: any,
  setcolor: any,
  setisloading: any,
  setpercent: any,
  setModalContent: any,
};

window["canmove"] = true;

class Viewer extends Component<Props, {}> {
  constructor(props) {
    super(props);

    this.canvas = "";
    this.engine = "";
    this.scene = "";
    this.camera = "";
    this.light = "";
    this.state = {};
  }
  onSceneMount = (e: SceneEventArgs) => {
    const { canvas, scene, engine } = e;
    this.canvas = canvas;
    this.engine = engine;
    this.scene = scene;

    this.setupCamera();
    this.setupLights();
    this.setupScene();
    engine.hideLoadingUI();

    engine.runRenderLoop(() => {
      if (scene) {
        scene.render();
        if (window.canmove) {
          inputController(this.camera, scene);
        }
      }
    });
  };

  setupCamera = () => {
    this.camera = new BABYLON.ArcRotateCamera(
      "Camera",
      1.57,
      1.4,
      7.5,
      new BABYLON.Vector3(0, 2, 30),
      this.scene
    );
    this.camera.attachControl(this.canvas, false);
    this.camera.checkCollisions = true;
    this.camera.fov = 1;
    this.camera.minZ = 0;
    this.camera.upperRadiusLimit = 7.5;
    this.camera.lowerRadiusLimit = 7.5;
    this.camera.keysUp.pop(38);
    this.camera.keysDown.pop(40);
    this.camera.keysLeft.pop(37);
    this.camera.keysRight.pop(39);

    this.camera.keysLeft.push(68);
    this.camera.keysRight.push(65);
  };

  setupLights = () => {
    const light = new BABYLON.HemisphericLight(
      "light1",
      new BABYLON.Vector3(0, 1, 0),
      this.scene
    );
    light.intensity = 0.7;

    const images = [
      "./skybox/nx.jpg",
      "./skybox/py.jpg",
      "./skybox/nz.jpg",
      "./skybox/px.jpg",
      "./skybox/ny.jpg",
      "./skybox/pz.jpg",
    ];

    const envTex = new BABYLON.CubeTexture.CreateFromImages(images, this.scene);
    this.scene.environmentIntensity = 1.6;

    const skybox = this.scene.createDefaultSkybox(envTex, true, 10000);
    skybox.material.reflectionTexture.rotationY = 3;
  };

  setupScene = () => {
    BABYLON.SceneLoader.LoadAssetContainer(
      mainModel,
      "",
      this.scene,
      (assets) => {
        assets.meshes[0].scaling = new BABYLON.Vector3(2, 2, -2);
        assets.meshes[0].position = new BABYLON.Vector3(160, 0, -675);
        assets.meshes[0].rotation = new BABYLON.Vector3(0, -Math.PI / 2, 0);
        spawnLocalPlayer(socket.id, this.camera, this.scene).then(() => {
          this.props.setisloading(false);
          joinRoom(
            this.scene,
            store.getState().AvatarReducer,
            "MainRoom",
            store.getState().UserReducer.userData.Name
          );
        });
        assets.addAllToScene();

        enableNavigation(assets);
        pointerEvents(this.scene, this.camera);
        keyboardEvents(this.scene);
        this.fetchLocationsData();
        spawnHotspots(
          this.scene,
          this.props.setModalContent,
          this.props.setopenModal,
          this.props.setModalfullScreen,
          this.props.setcolor,
          this.camera
        );

        //arialButtons
        createArialCameraAndButtons(this.scene, this.canvas);
      },
      (e) => {
        this.props.setpercent(Math.floor((e.loaded / 59028904) * 100));
      }
    );
  };

  fetchLocationsData = () => {
    fetch("/Locations.json")
      .then((response) => response.json())
      .then((data) => {
        Object.keys(data).forEach((location) => {});
      });
  };

  createAnnotations = (name) => {
    GUI.Control.prototype.getScene = function () {
      return this.scene;
    };

    const fullScreenUI = new GUI.AdvancedDynamicTexture.CreateFullscreenUI(
      "FullScreen",
      true,
      this.scene
    );
    const panel = new GUI.Rectangle("Panel");
    panel.thickness = 0;
    fullScreenUI.addControl(panel);

    const bgImg = new GUI.Image(
      "BG",
      "https://storage.googleapis.com/gigabyte-metaverse.appspot.com/Image_07.png"
    );
    bgImg.autoScale = true;
    panel.addControl(bgImg);

    //annotation
    const annotation = new GUI.Button(name);
    annotation.width = "40px";
    annotation.height = "40px";
    annotation.clipChildren = false;
    annotation.clipContent = false;
    annotation.color = "Transparent";
    annotation.onPointerClickObservable.add((e) => {
      //teleport
      console.log(e);
    });
    annotation.onPointerEnterObservable.add(() => {
      innerCircle.width = "15px";
      innerCircle.height = "15px";
    });
    annotation.onPointerOutObservable.add(() => {
      innerCircle.width = "7px";
      innerCircle.height = "7px";
    });

    panel.addControl(annotation);

    const innerCircle = new GUI.Ellipse("InnerRing");
    innerCircle.thickness = 0;
    innerCircle.background = "#E2583E";
    innerCircle.width = "7px";
    innerCircle.height = "7px";
    annotation.addControl(innerCircle);

    const outerCircle = new GUI.Ellipse("OuterCircle");
    annotation.addControl(outerCircle);
    outerCircle.width = "7px";
    outerCircle.height = "7px";
    outerCircle.color = "Yellow";

    BABYLON.Animation.CreateAndStartAnimation(
      "RingAnim",
      outerCircle,
      "widthInPixels",
      30,
      45,
      innerCircle.widthInPixels,
      60,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
    );
    BABYLON.Animation.CreateAndStartAnimation(
      "RingAnim",
      outerCircle,
      "heightInPixels",
      30,
      45,
      innerCircle.heightInPixels,
      60,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
    );
    BABYLON.Animation.CreateAndStartAnimation(
      "RingAnim",
      outerCircle,
      "alpha",
      30,
      45,
      outerCircle.alpha,
      0,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
    );
  };

  render() {
    return (
      <>
        <BabylonScene id="studio" onSceneMount={this.onSceneMount} />
      </>
    );
  }
}

export default Viewer;
