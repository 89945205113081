import * as BABYLON from "babylonjs";
import * as GUI from "babylonjs-gui";
import { localPlayer } from "./Player";

const ArialButtons = [
  {
    buttonName: "The Studio and workshop space",
    posX: 59.279,
    poxY: 27.666,
    poxZ: -169.445,
    playerX: 32.2,
    playerY: 1.3,
    playerZ: -177.174,
    rx: 0,
    ry: (Math.PI / 2) * 3,
    rz: 0,
    alpha: 3.199,
    beta: 1.418,
  },
  {
    buttonName: "Left Hall",
    posX: -50.542,
    poxY: 27.271,
    poxZ: -178.875,
    playerX: -18.655,
    playerY: 1.3,
    playerZ: -187.894,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.397,
  },
  {
    buttonName: "Right Hall",
    posX: -59.929,
    poxY: 31.832,
    poxZ: -13.117,
    playerX: -18.348,
    playerY: 1.3,
    playerZ: -8.641,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.317,
  },
  {
    buttonName: "Central Hall",
    posX: -52.407,
    poxY: 30.36,
    poxZ: -102.739,
    playerX: -19.37,
    playerY: 1.3,
    playerZ: -99.694,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.347,
  },
  {
    buttonName: "Information Desk",
    posX: -18.667,
    poxY: 15.862,
    poxZ: -59.037,
    playerX: -6.193,
    playerY: 1.3,
    playerZ: -60.485,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0.095,
    beta: 1.216,
  },
  {
    buttonName: "ENGULF by Narayan Sinha",
    posX: -21.742,
    poxY: 18.419,
    poxZ: 25.99,
    playerX: -24.0,
    playerY: 1.3,
    playerZ: 7.539,
    rx: 0,
    ry: Math.PI,
    rz: 0,
    alpha: -1.511,
    beta: 1.31,
  },
  {
    buttonName: "The Auditorium",
    posX: 105.023,
    poxY: 34.974,
    poxZ: -97.82,
    playerX: 51.367,
    playerY: 1.3,
    playerZ: -104.151,
    rx: 0,
    ry: (Math.PI / 2) * 3,
    rz: 0,
    alpha: 3.21,
    beta: 1.356,
  },
  {
    buttonName: "Reset by Serendipity Arts",
    posX: 0.737,
    poxY: 12.53,
    poxZ: -135.145,
    playerX: 6.226,
    playerY: 1.3,
    playerZ: -133.224,
    rx: 0,
    ry: (Math.PI / 2) * 3,
    rz: 0,
    alpha: 3.159,
    beta: 1.495,
  },
  {
    buttonName: "Kochi rewind by Kochi Biennale Foundation",
    posX: 21.31,
    poxY: 13.765,
    poxZ: 31.188,
    playerX: 22.994,
    playerY: 1.3,
    playerZ: 42.512,
    rx: 0,
    ry: 0,
    rz: 0,
    alpha: 1.565,
    beta: 1.389,
  },
  {
    buttonName: "the future is femme by aravani art project",
    posX: 8.724,
    poxY: 15.954,
    poxZ: 250.692,
    playerX: -0.228,
    playerY: 1.3,
    playerZ: 283.085,
    rx: 0,
    ry: 0,
    rz: 0,
    alpha: 0.948,
    beta: 1.389,
  },
  {
    buttonName: "India Art Fair Facade by Anshuka Mahapatra",
    posX: -24.543,
    poxY: 22.032,
    poxZ: -102.095,
    playerX: 13.553,
    playerY: 1.3,
    playerZ: -86.695,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.446,
  },
];

let arialCamera;
let scene;
let playerCam;
let canvas;

let fadeInPanel;

let audioPlayed = false;

const buttons = [];

const playAudio = (scene) => {
  var music = new BABYLON.Sound(
    "Music",
    "/Untitled-2022-04-26.wav",
    scene,
    null,
    {
      loop: false,
      autoplay: true,
    }
  );
};

export const createArialCameraAndButtons = (_scene, _canvas) => {
  GUI.Control.prototype.getScene = function () {
    return _scene;
  };

  var camera = new BABYLON.ArcRotateCamera(
    "camera1",
    0,
    1,
    370,
    new BABYLON.Vector3(0, 0, -40),
    scene
  );
  arialCamera = camera;
  playerCam = _scene.activeCamera;
  scene = _scene;
  canvas = _canvas;
  scene.activeCamera.detachControl(canvas);
  camera.attachControl(canvas, false);
  camera.lowerBetaLimit = camera.upperBetaLimit = 1;
  camera.lowerRadiusLimit = camera.upperRadiusLimit = 370;
  scene.activeCamera = camera;

  //buttons
  ArialButtons.forEach((buttonObj) => {
    const butPlane = new BABYLON.MeshBuilder.CreatePlane(
      buttonObj.buttonName,
      { size: 22 },
      scene
    );
    buttons.push(butPlane);
    butPlane.position = new BABYLON.Vector3(
      buttonObj.posX,
      buttonObj.poxY,
      buttonObj.poxZ
    );
    butPlane.billboardMode = 7;

    const advTex = new GUI.AdvancedDynamicTexture.CreateForMesh(
      butPlane,
      512,
      512,
      true
    );
    const panel = new GUI.Rectangle("Panel");
    panel.thickness = 0;
    advTex.addControl(panel);

    //annotation
    butPlane.actionManager = new BABYLON.ActionManager(scene);

    butPlane.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPickTrigger,
        function (evt) {
          teleportToHotspot(
            buttonObj.playerX,
            buttonObj.playerY,
            buttonObj.playerZ,
            buttonObj.rx,
            buttonObj.ry,
            buttonObj.rz,
            buttonObj.alpha,
            buttonObj.beta
          );
          if (!audioPlayed) {
            playAudio(scene);
            audioPlayed = true;
          }
        }
      )
    );
    butPlane.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOverTrigger,
        function (evt) {
          innerCircle.width = "150px";
          innerCircle.height = "150px";
        }
      )
    );
    butPlane.actionManager.registerAction(
      new BABYLON.ExecuteCodeAction(
        BABYLON.ActionManager.OnPointerOutTrigger,
        function (evt) {
          innerCircle.width = "80px";
          innerCircle.height = "80px";
        }
      )
    );

    const innerCircle = new GUI.Ellipse("InnerRing");
    innerCircle.thickness = 0;
    innerCircle.background = "#E2583E";
    innerCircle.width = "80px";
    innerCircle.height = "80px";
    panel.addControl(innerCircle);

    const outerCircle = new GUI.Ellipse("OuterCircle");
    panel.addControl(outerCircle);
    outerCircle.width = "100px";
    outerCircle.height = "100px";
    outerCircle.color = "Yellow";
    outerCircle.thickness = 10;

    BABYLON.Animation.CreateAndStartAnimation(
      "RingAnim",
      outerCircle,
      "widthInPixels",
      30,
      45,
      innerCircle.widthInPixels,
      500,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
    );
    BABYLON.Animation.CreateAndStartAnimation(
      "RingAnim",
      outerCircle,
      "heightInPixels",
      30,
      45,
      innerCircle.heightInPixels,
      500,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
    );
    BABYLON.Animation.CreateAndStartAnimation(
      "RingAnim",
      outerCircle,
      "alpha",
      30,
      45,
      outerCircle.alpha,
      0,
      BABYLON.Animation.ANIMATIONLOOPMODE_CYCLE
    );

    // var gizmoManager = new BABYLON.GizmoManager(scene);
    // gizmoManager.positionGizmoEnabled = true;
    // gizmoManager.usePointerToAttachGizmos = false;
    // gizmoManager.attachToMesh(butPlane);
  });

  //fullscreen ui
  const fullScreenUI = new GUI.AdvancedDynamicTexture.CreateFullscreenUI(
    "FullScreen",
    true,
    scene
  );
  const FadeIN = new GUI.Rectangle("FadeIN");
  FadeIN.thickness = 0;
  fullScreenUI.addControl(FadeIN);
  fadeInPanel = FadeIN;
  FadeIN.alpha = 0;
  FadeIN.background = "Black";
};

export const teleportToHotspot = (x, y, z, rotX, rotY, rotZ, alpha, beta) => {
  //loading fade in
  const fadeIn = BABYLON.Animation.CreateAndStartAnimation(
    "RingAnim",
    fadeInPanel,
    "alpha",
    30,
    30,
    0,
    1,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
    null,
    () => {
      arialCamera.detachControl(canvas);
      playerCam.attachControl(canvas, false);
      playerCam.alpha = alpha;
      playerCam.beta = beta;
      scene.activeCamera = playerCam;
      localPlayer.parent.position = new BABYLON.Vector3(x, y, z);
      localPlayer.parent.rotation = new BABYLON.Vector3(rotX, rotY, rotZ);

      //hideButtons
      buttons.forEach((but) => {
        but.setEnabled(false);
      });

      BABYLON.Animation.CreateAndStartAnimation(
        "RingAnim",
        fadeInPanel,
        "alpha",
        30,
        30,
        1,
        0,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
      );
    }
  );
};

export const showArialView = () => {
  //loading fade in
  playerCam.detachControl(canvas);
  const fadeIn = BABYLON.Animation.CreateAndStartAnimation(
    "RingAnim",
    fadeInPanel,
    "alpha",
    30,
    30,
    0,
    1,
    BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT,
    null,
    () => {
      arialCamera.attachControl(canvas, false);
      scene.activeCamera = arialCamera;
      //hideButtons
      buttons.forEach((but) => {
        but.setEnabled(true);
      });

      BABYLON.Animation.CreateAndStartAnimation(
        "RingAnim",
        fadeInPanel,
        "alpha",
        30,
        30,
        1,
        0,
        BABYLON.Animation.ANIMATIONLOOPMODE_CONSTANT
      );
    }
  );
};
