const userInitialState = {
  userData: {},
  BMWZone: false,
  userCount: 0,
  leaderBoard: [],
};

export const userReducer = (state = userInitialState, action) => {
  switch (action.type) {
    case "ADD_USER_INFO": {
      return {
        ...state,
        userData: action.data,
      };
    }
    case "UPDATE_ZONE": {
      return { ...state, BMWZone: action.data };
    }
    case "UPDATE_USER_COUNT": {
      return {
        ...state,
        userCount: action.data,
      };
    }
    default: {
      return state;
    }
  }
};
