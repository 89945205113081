import * as BABYLON from "babylonjs";

import * as GUI from "babylonjs-gui";
import { teleportToHotspot } from "./ArialView";
import { BMWContent } from "./ExperienceUI/Content/Content";
import { arr } from "./ExperienceUI/ExperienceUI";
import { localPlayer } from "./Player";

let camera = null;
let SetModalContent = null;
let SetmodalOpen = null;
export function spawnHotspots(
  scene,
  setModalContent,
  setopenModal,
  setModalfullScreen,
  setcolor,
  Camera
) {
  camera = Camera;
  SetmodalOpen = setopenModal;
  SetModalContent = setModalContent;

  let locationsData = [
    {
      btnName: "ENTER LEFT HALL",
      text: "ENTER LEFT HALL",
      x: -22.94,
      y: 0.73,
      z: -184.22,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "ENTER RIGHT HALL",
      text: "ENTER RIGHT HALL",
      x: -29.1,
      y: 2.91,
      z: -13.05,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "ENTER CENTRAL HALL",
      text: "ENTER CENTRAL HALL",
      x: -25.849,
      y: 2.91,
      z: -101.97,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "INDIA ART FAIR FACADE BY ANSHUKA MAHAPATRA",
      text: "KNOW MORE",
      x: -25.757,
      y: 10,
      z: -102.97,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "INFORMATION DESK",
      text: "KNOW MORE",
      x: -16.13,
      y: 3.62,
      z: -58.8,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "ENGULF by NARAYAN SINHA",
      text: "KNOW MORE",
      x: -19.53,
      y: 1.66,
      z: 17.89,
      rotY: 0,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "BMW",
      text: "CLICK TO KNOW MORE",
      x: -54.36,
      y: 1.05,
      z: -201.54,
      rotY: 0,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "AUDITORIUM",
      text: "ENTER THE AUDITORIUM",
      x: 63.29,
      y: 2.83,
      z: -100.09,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "ENTER THE STUDIO AND WORKSHOP SPACE",
      text: "ENTER THE STUDIO AND WORKSHOP SPACE",
      x: 42.41,
      y: 2.71,
      z: -182.32,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "KOCHI REWIND BY KOCHI BIENNALE FOUNDATION",
      text: "KNOW MORE",
      x: 19.278,
      y: 2.71,
      z: 28.957,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "THE FUTURE IS FEMME BY ARAVANI ART PROJECT",
      text: "KNOW MORE",
      x: -5.852,
      y: 7.426,
      z: 277.163,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
    {
      btnName: "RESET BY SERENDIPITY ARTS",
      text: "KNOW MORE",
      x: 8.68,
      y: 5.44,
      z: -137.31,
      rotY: Math.PI / 2,
      rotX: 0,
      rotZ: 0,
    },
  ];

  let locations = {};

  locationsData.forEach((location) => {
    const plane = BABYLON.MeshBuilder.CreatePlane(
      `${location.btnName}`,
      { size: 3, sideOrientation: BABYLON.Mesh.DOUBLESIDE },
      scene
    );

    plane.position = new BABYLON.Vector3(location.x, location.y, location.z);
    plane.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;

    var advancedTexture = GUI.AdvancedDynamicTexture.CreateForMesh(plane);

    var button1 = GUI.Button.CreateSimpleButton("but1", `${location.text}`);
    button1.width = 1;
    button1.height = 0.4;
    button1.color = "white";
    button1.fontSize = 100;
    button1.background = "black";

    button1.onPointerUpObservable.add(function () {
      if (button1.textBlock.text === "CLICK TO KNOW MORE") {
        SetModalContent(
          <BMWContent
            onCloseClick={() => {
              SetmodalOpen(false);
            }}
          />
        );
        setModalfullScreen(true);
        setcolor("white");
        SetmodalOpen(true);
        return;
      }

      const temp = arr.filter((e) => {
        return e.btnName === location.btnName;
      });

      if (temp.length > 0) {
        setModalfullScreen(true);
        setcolor("white");
        temp[0].onClick(setopenModal, setModalContent);
      }
    });
    advancedTexture.addControl(button1);
  });

  Object.keys(locations).forEach((location) => {
    // var gizmoManager = new BABYLON.GizmoManager(scene);
    // gizmoManager.positionGizmoEnabled = true;
    // gizmoManager.usePointerToAttachGizmos = false;
    // gizmoManager.attachToMesh(plane);
  });
}

export const teleport = () => {
  teleportToHotspot(-44.963, 1.3, -186.015, 0, 0, 0, 1.573, 1.332);
  // localPlayer.parent.rotation = new BABYLON.Vector3.Zero();
  setTimeout(() => {
    camera.detachControl(camera.getEngine().getRenderingCanvas());
    window.canmove = false;
  }, 1200);
};

export const fade = (element, duration) => {
  (function increment(value = 0) {
    element.style.opacity = String(value);
    if (element.style.opacity !== "1") {
      setTimeout(() => {
        increment(value + 0.1);
      }, duration / 10);
    }
  })();
};
