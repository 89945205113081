import ImageViewer from "../../../../Components/ImageViewer/ImageViewer";

//Auditorium
import Audiimg1 from "./IAF Images/Auditorium/1000-IAF-{Day-2}.JPG";
import Audiimg2 from "./IAF Images/Auditorium/audi interior.JPG";
import Audiimg3 from "./IAF Images/Auditorium/Audi Talk.jpeg";
import Audiimg4 from "./IAF Images/Auditorium/Auditorium - Interior 4.jpg";
import Audiimg5 from "./IAF Images/Auditorium/auditorium interior 1.JPG";
import Audiimg6 from "./IAF Images/Auditorium/D85_4034.jpg";
import Audiimg7 from "./IAF Images/Auditorium/D85_4044.jpg";

//Central Hall
import CHallimg1 from "./IAF Images/Central Hall/1.jpg";
import CHallimg2 from "./IAF Images/Central Hall/3.jpg";
import CHallimg3 from "./IAF Images/Central Hall/4.JPG";
import CHallimg4 from "./IAF Images/Central Hall/9.jpg";
import CHallimg5 from "./IAF Images/Central Hall/10.JPG";
import CHallimg6 from "./IAF Images/Central Hall/11.jpg";

//Kochie
import Kochieimg1 from "./IAF Images/Kochi Biennale/11-SHILPA-GUPTA-800-700.jpg";
import Kochieimg2 from "./IAF Images/Kochi Biennale/Kochi_Muziris_Biennale_Facebook1 (1).jpg";
import Kochieimg3 from "./IAF Images/Kochi Biennale/P90170917-kochi-muziris-biennale-2014-c-bmw-ag-12-2014-2250px.jpg";

//Engulf
import Engulfimg1 from "./IAF Images/Engulf Narayan Sinha/Narayan Sinha ref image.png";

//Left Hall
import LHfimg1 from "./IAF Images/Left Hall/3D Render.jpeg";

//Serendipity
import Simg1 from "./IAF Images/Reset by Serendipity Arts/6O1A1463 - Serendipity.png";
import Simg2 from "./IAF Images/Reset by Serendipity Arts/interior.jpg";

//Right Hall
import RHimg1 from "./IAF Images/Right Hall/3.JPG";
import RHimg2 from "./IAF Images/Right Hall/4.JPG";
import RHimg3 from "./IAF Images/Right Hall/5.jpg";
import RHimg4 from "./IAF Images/Right Hall/7.jpg";
import RHimg5 from "./IAF Images/Right Hall/D85_2445.jpg";
import RHimg6 from "./IAF Images/Right Hall/PWD_6669.jpg";

//Studio
import Studioimg1 from "./IAF Images/Studio & workshop/IMG06027.jpg";
import Studioimg2 from "./IAF Images/Studio & workshop/Inclusivity Space.jpg";
import Studioimg3 from "./IAF Images/Studio & workshop/Maitri Art Lab.JPG";
import Studioimg4 from "./IAF Images/Studio & workshop/Workshop space 2.jpeg";
import Studioimg5 from "./IAF Images/Studio & workshop/Workshop space.jpeg";

//Aravani
import Aravaniimg1 from "./IAF Images/Aravani Art Project/frame 1.1.png";
import Aravaniimg2 from "./IAF Images/Aravani Art Project/frame 1.2.png";
import Aravaniimg3 from "./IAF Images/Aravani Art Project/Frame 2.png";
import Aravaniimg4 from "./IAF Images/Aravani Art Project/frame 3.png";
import Aravaniimg5 from "./IAF Images/Aravani Art Project/frame 4.png";
import Aravaniimg6 from "./IAF Images/Aravani Art Project/frame 5.png";

//Information Desk
import Infoimg1 from "./IAF Images/Information Desk/Info Desk.JPG";

//Facade
import Fimg1 from "../../../LandingPage/Assets/LandingPageBG.png";
import "./Content.css";
import Dot from "./Assets/Dot.png";
import { teleport } from "../../Interaction";
import { store } from "../../../../Redux/redux";
import { UPDATE_ZONE } from "../../../../Redux/actions/userDataAction";

export const AuditoriumContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <h1 style={{ padding: 0, margin: 0 }}>The Auditorium</h1>
            <div className="text_Content" style={{ maxHeight: "65vh" }}>
              <p>
                India Art Fair will curate talks, panels and performances by
                leading voices in the art world—from artists to curators,
                gallerists and collectors—in its AUDITORIUM TALKS PROGRAMME.
              </p>
              <p>
                The talks programme will feature the biggest voices in art
                including a BMW Art Talk with celebrated Indian artist Atul
                Dodiya in conversation with his friend and gallerist Shireen
                Gandhy; a Collector Talk by Kiran Nadar, India’s leading art
                patron and founder of the Kiran Nadar Museum of Art; a
                conversation between the iconic Indian Magnum photographer Raghu
                Rai and the French Ambassador to India, Emmanuel Lenain; a look
                back at past 25 years of leading Indian art magazine, ‘Art
                India’ with Founder Sangita Jindal, Editor Abhay Sardesai and
                past Editors Anupa Mehta and Girish Shahane; and a memorial talk
                on the late Satish Gujral given by the renowned art critic
                Gayatri Sinha.
              </p>
              <p>
                Making connections beyond visual arts, art collector and patron,
                Feroze Gujral, architects Sonali Rastogi and Ashiesh Shah,
                editor-in-chief of the creative platform STIR, Amit Gupta, and
                leading name in art and advertising V. Sunil, will discuss the
                rise of a distinctly ‘South Asian Aesthetic’ globally. Nikhil
                Chopra and Madhavi Gore of HH Art Spaces in Goa, will be in
                conversation with Mario D’Souza and Dr. Shwetal Patel of Kochi
                Muziris Biennale on the importance of sustaining and nurturing
                cutting-edge creative thinking through friendships and
                collaborations.
              </p>
              <p>
                Young and trailblazing voices in art will equally be in the
                spotlight in the India Art Fair with a talk on the importance of
                peer-to-peer support with Ayesha Singh of Art Chain India,
                Poornima Sukumar and Sadhna Prasad from Aravani Art Project,
                Kriti Sood of LAND and Shilo Shiv Suleman of Fearless
                Collective; a film presentation and talk by South Asian
                diasporic artist Hetain Patel; artist presentations by India Art
                Fair Artists in Residence Haroun Hayward and Indu Antony; and a
                talk on art and technology titled ‘NFTease’ to address and give
                context to the rise around NFTs by Indian NFT artist Raghava
                K.K., Om Malviya of blockchain platform Tezos India, Aparajita
                Jain of the Indian NFT platform Terrain.art, Kamya Ramachandran
                of techart platform BeFantastic and RadBot creator Vasundhara
                Das.
              </p>
              <p>
                The Auditorium will see the launch of two major publications
                including the seminal publication on 20th Century Indian Art
                published by Thames & Hudson and Art Alive, and the
                much-anticipated book Moving Focus, India edited by Mortimer
                Chatterjee and published by The Shoestring Publisher.
              </p>
              <p>
                Besides our Talks, the Auditorium will also include our
                Inclusion Lab, and showcases by Kala Chaupal Trust, Connect ASAP
                and Offset Projects.
              </p>
            </div>
            <button
              className="black_btn"
              onClick={() => {
                window.open("https://indiaartfair.in/programme");
              }}
            >
              See Full schedule
            </button>
          </div>
        )}
        imgarr={[
          Audiimg1,
          Audiimg2,
          Audiimg3,
          Audiimg4,
          Audiimg5,
          Audiimg6,
          Audiimg7,
        ]}
      />
    </div>
  );
};
export const BMWContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <h1 style={{ padding: 0, margin: 0 }}>
              BMW iX ‘Suno’ by Faiza Hasan
            </h1>
            <div className="text_Content" style={{ maxHeight: "75vh" }}>
              <p>
                Working towards sustainability involves not only large scale
                participation, but also that the needs of our communities are
                heard and met in the process. For me, sustainability and
                circularity are closely connected to the choices we make when it
                comes to production, consumption, technology as well as to our
                community.” - Faiza Hasan, Indian contemporary artist
              </p>
              <p>
                ‘The Future is Born of Art’ Commission is a first-of-its-kind
                commission by BMW India and India Art Fair, and was awarded to
                Indian contemporary artist, Faiza Hasan, to design a BMW iX car
                wrap with her vision of a sustainable future. The canvas for the
                wrap, the BMW iX is BMW’s first all-electric launch in India and
                exemplifies principles of sustainability throughout its
                lifecycle. ‘Suno’ builds upon the BMW iX’s sustainable
                philosophy with Urdu words in gold lettering that spell out
                ‘umeed’ or ‘to hope’, ‘tasawur’ or to ‘imagine’, ‘nigehbaan’ or
                ‘to safeguard’ and the title, ‘suno’ or ‘to listen’, all placed
                among a host of portraits of people from the community.
              </p>
              <p>
                An unprecedented commission in the country, the project was
                created to champion emerging Indian artists, propelling them
                onto the world stage, and to further support BMW’s commitment to
                promoting Indian art, sustainability and innovation.
              </p>
            </div>
          </div>
        )}
        imgarr={[LHfimg1]}
      />
    </div>
  );
};

export const IndianArtFareContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <div style={{ lineHeight: "2.5rem" }}>
              <h2 style={{ padding: 0, margin: 0 }}>ANSHUKA MAHAPATRA</h2>
              <h2 style={{ padding: 0, margin: 0 }}>INDIA ART FAIR FACADE</h2>
              <h4 style={{ padding: 0, margin: 0, lineHeight: "1.5em" }}>
                SUPPORTED BY THE GUJRAL FOUNDATION AND ARTDEMIC
              </h4>
            </div>
            <div
              style={{
                textAlign: "left",
                lineHeight: "1.5rem",
              }}
            >
              The India Art Fair Facade is created by artist Anshuka Mahapatra
              and celebrates the ideas of hope, love, life and togetherness.
              Using poetic phrases in seven regional Indian languages, including
              Odia, Assamese, Kannada, Hindi, Gujarati, Malayalam and English,
              the artist brings to mind fleeting and evocative moments of beauty
              from everyday life. The artist, whose practice is rooted in
              printmaking, was selected to design the fair facade through an
              open call.
            </div>
          </div>
        )}
        imgarr={[Fimg1]}
      />
    </div>
  );
};
export const StudioContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <div style={{ lineHeight: "2.5rem" }}>
              <h2 style={{ padding: 0, margin: 0 }}>
                The Studio and Workshop Space
              </h2>
            </div>
            <div className="text_Content" style={{ maxHeight: "60vh" }}>
              <p>
                THE STUDIO, a brand new space at the fair this year, is
                dedicated to making bridges across creative fields beyond visual
                art including design and technology and partners from these
                fields making presentations.
              </p>
              <p>
                Bringing a spirit of playfulness to the fair, the wide range of
                projects in The Studio include games from the artist duo Thukral
                and Tagra; interactive bots from an Indian techart platform
                BeFantastic supported by Tezos India; a showcase by the winners
                of Serendipity Art Foundation’s Virtual Art grants; a
                presentation by the newly opened Museum of Art and Photography
                on their ‘digital museum’ and collections; a viewer-centric
                installation on pollution in cities by Khoj International
                Artists’ Association; an AR project by artist Muzzumil Ruheel.
              </p>
              <p>
                The Studio will also host an installation of shadow puppetry by
                Space 118 Fine Art Grant winner Tapan Moharana’, a special
                artist initiated project by Shilpa Gupta focussed on
                artist-designed T-shirts with the phrase ‘I Look at Things with
                Eyes Different From Yours’; as well as a stage for performances
                by India Art Fair Artists in Residence, Gurjeet Singh and Arpita
                Akhanda.
              </p>
              <p>
                The refreshed WORKSHOP SPACE includes a programme of hands-on
                activities and workshops titled ‘Rediscovery’ for visitors
                curated by Kriti Sood of LAND (Learning Through Arts, Narrative
                and Discourse). The workshops will be held in Hindi and English,
                with a select number in Sign Language, and will be open to the
                public with one-on-one engagement through experience sheets and
                a 3-day art pedagogy programme for educators around India. There
                will also be a special workshop on Indian embroidery techniques
                led by Etsy Seller, Ritu Jain.
              </p>
            </div>
            <button
              className="black_btn"
              onClick={() => {
                window.open(
                  "https://indiaartfair.in/programme-category/workshops"
                );
              }}
            >
              See Full schedule
            </button>
          </div>
        )}
        imgarr={[Studioimg1, Studioimg2, Studioimg3, Studioimg4, Studioimg5]}
      />
    </div>
  );
};
export const LeftHallContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <h2 style={{ padding: 0, margin: 0 }}>Exhibitors</h2>
            <div className="text_Content" style={{ maxHeight: "40vh" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Alkazi Theatre Archives
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Chennai Photo Biennale Foundation
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Foundation for Indian Contemporary Art (FICA)
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>HH Art Spaces</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  In Memoriam - Satish Gujral
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Kochi Biennale Foundation
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Kolkata Centre for Creativity
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Liszt Institute - Hungarian Cultural Centre
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Museo Camera</h4>
              </div>
            </div>
            <h2 style={{ padding: 0, margin: 0 }}>Partners</h2>
            <div className="text_Content" style={{ maxHeight: "40vh" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  BMW Collectors' Lounge
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  BMW iX Suno by Faiza Hasan
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>DIA COLOR</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  India Art Fair VIP Lounge
                </h4>
              </div>
            </div>
            <button
              className="black_btn"
              onClick={() => {
                teleport();
                onCloseClick();
                store.dispatch(UPDATE_ZONE(true));
              }}
              dangerouslySetInnerHTML={{
                __html: "BMW iX <i>Suno</i> by Faiza Hasan",
              }}
            ></button>
          </div>
        )}
        imgarr={[LHfimg1]}
      />
    </div>
  );
};
export const CentralHallContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <h2 style={{ padding: 0, margin: 0 }}>Exhibitors</h2>
            <div className="text_Content" style={{ maxHeight: "40vh" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}> Aicon</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Aicon Contemporary</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Anant Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Apparao Galleries</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>APRE Art House</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Heritage</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Incept</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Pilgrim</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Blueprint12</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Delhi Crafts Council</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Fabuleux Arts</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Galeria Karla Osorio</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Galerie ISA</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallery Art Positive</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallery Ragini</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  In Memoriam - Rini Dhumal
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Inherited Arts Forum</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Kalakriti Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Kiran Nadar Museum of Art (KNMA)
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Modern Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Nature Morte</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Ojas Arts</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Pichvai Tradition & Beyond
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Project 88</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Rukshaan Art</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Sakshi Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Shrine Empire & Space Studio
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Terrain.art</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Wonderwall</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>ZOCA</h4>
              </div>
            </div>
            <h2 style={{ padding: 0, margin: 0 }}>Partners</h2>
            <div className="text_Content">
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>æquō</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4
                  style={{ padding: 0, margin: 0 }}
                  dangerouslySetInnerHTML={{ __html: "ART <i>India</i>" }}
                ></h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  India Sotheby's International Realty
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>TAKE on Art</h4>
              </div>
            </div>
            <h2 style={{ padding: 0, margin: 0 }}>Restaurant</h2>
            <div className="text_Content">
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>The Tangra Project</h4>
              </div>
            </div>
          </div>
        )}
        imgarr={[
          CHallimg1,
          CHallimg2,
          CHallimg3,
          CHallimg4,
          CHallimg5,
          CHallimg6,
        ]}
      />
    </div>
  );
};
export const RightHallContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <h2 style={{ padding: 0, margin: 0 }}>Exhibitors</h2>
            <div className="text_Content" style={{ maxHeight: "60vh" }}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Aakriti Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Akar Prakar</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Akara Art</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Anupa Mehta Arts</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Archer Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Alive Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Centrix Space</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Houz</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Art Musings</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Chatterjee & Lal</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Chawla Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Chemould Prescott Road
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Crayon Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>DAG</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Dhoomimal Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Emami Art</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Exhibit 320</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Experimenter</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Galerie ISA</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallerie Nvya</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallery Art Exposure</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallery Art.Motif</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallery Espace</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Gallery Mirchandani + Steinreuecke
                </h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Gallery Veda</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>GALLERYSKE</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Grosvenor Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Jhaveri Contemporary</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Latitude 28</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Palette Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Shrine Empire</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Studio Art</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Tao Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>TARQ</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>The Guild</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Threshold Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Vadehra Art Gallery</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>
                  Vida Heydari Contemporary
                </h4>
              </div>
            </div>

            <h2 style={{ padding: 0, margin: 0 }}>Restaurant</h2>
            <div className="text_Content">
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>Elma's</h4>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", gap: "1em" }}
              >
                <img src={Dot} />
                <h4 style={{ padding: 0, margin: 0 }}>CAARA</h4>
              </div>
            </div>
          </div>
        )}
        imgarr={[RHimg1, RHimg2, RHimg3, RHimg4, RHimg5, RHimg6]}
      />
    </div>
  );
};
export const EngulfContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <h2 style={{ padding: 0, margin: 0 }}>NARAYAN SINHA</h2>
              <h2 style={{ padding: 0, margin: 0 }}>ENGULF</h2>
              <h2 style={{ padding: 0, margin: 0 }}>SUPPORTED BY</h2>
              <h2 style={{ padding: 0, margin: 0 }}>ARTS ANANDA TRUST</h2>
            </div>
            <div className="text_Content">
              <p>
                The large and intricately constructed sculpture is made of scrap
                silencer pipes from trucks. Created and assembled during the
                pandemic, the tangled nature of the sculpture evokes the dark
                smoke and challenges of the past year, while showing us the
                balance and harmony which is ever-present in nature.
              </p>
            </div>
          </div>
        )}
        imgarr={[Engulfimg1]}
      />
    </div>
  );
};
export const SerendipityContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <h2 style={{ padding: 0, margin: 0 }}> MULTIPLE ARTISTS</h2>
              <h2 style={{ padding: 0, margin: 0 }}>RESET</h2>
              <h2 style={{ padding: 0, margin: 0 }}>SUPPORTED BY</h2>
              <h2 style={{ padding: 0, margin: 0 }}>SERENDIPITY ARTS</h2>
            </div>
            <div className="text_Content">
              <p>
                The project room is a space for discovery, play and rest with
                artist books and publications that will give us a view into the
                multidisciplinary programmes and activities at Serendipity Arts.
                The space will also include interactive projects by the
                Foundation’s Food Lab Grant winners, Sharanya Deepak and Tansha
                Vohra.
              </p>
            </div>
          </div>
        )}
        imgarr={[Simg2, Simg1]}
      />
    </div>
  );
};

export const KochiContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <h2 style={{ padding: 0, margin: 0 }}>MULTIPLE ARTISTS</h2>
              <h2 style={{ padding: 0, margin: 0 }}>KOCHI REWIND</h2>
              <h2 style={{ padding: 0, margin: 0 }}>SUPPORTED BY</h2>
              <h2 style={{ padding: 0, margin: 0 }}>
                KOCHI BIENNALE FOUNDATION
              </h2>
            </div>
            <div className="text_Content">
              <p>
                A large screen will recall iconic artworks including photographs
                and excerpts of video installations from the past four editions
                of the Kochi-Muziris Biennale. This year marks the 10th
                anniversary of the biennale which has become a landmark platform
                for art in the region and internationally.
              </p>
            </div>
          </div>
        )}
        imgarr={[Kochieimg1, Kochieimg2, Kochieimg3]}
      />
    </div>
  );
};
export const AravaniContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div className="Side_Content_root">
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <h2 style={{ padding: 0, margin: 0 }}>ARAVANI ART PROJECT</h2>
              <h2 style={{ padding: 0, margin: 0 }}>THE FUTURE IS FEMME</h2>
              <h2 style={{ padding: 0, margin: 0 }}>SUPPORTED BY</h2>
              <h2 style={{ padding: 0, margin: 0 }}>SAFFRONART FOUNDATION</h2>
            </div>
            <div className="text_Content">
              <p>
                The 50-feet long mural painted by the trans-artist collective
                depicts a vision for a binary-free and inclusive world. It
                imagines what it looks like to live and love freely, celebrating
                oneself and nurturing a community that thrives on openness and
                care.
              </p>
              <p>
                * These are work in progress images. The final artwork can be
                viewed at India Art Fair, NSIC Grounds, Okhla.
              </p>
            </div>
          </div>
        )}
        imgarr={[
          Aravaniimg1,
          Aravaniimg2,
          Aravaniimg3,
          Aravaniimg4,
          Aravaniimg5,
          Aravaniimg6,
        ]}
      />
    </div>
  );
};
export const InfoContent = ({ onCloseClick }) => {
  return (
    <div className="Content_Holder">
      <ImageViewer
        onCloseClick={onCloseClick}
        sideContent={() => (
          <div
            className="Side_Content_root"
            style={{ justifyContent: "center", textAlign: "center" }}
          >
            <h1 style={{ padding: 0, margin: 0 }}>INFORMATION DESK</h1>
            <button
              className="black_btn"
              onClick={() => {
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/iaf-metaverse.appspot.com/o/Fair%20Guide.pdf?alt=media&token=9d0ac804-827b-40b3-84ce-4deea5b9cb64"
                );
              }}
            >
              FAIR GUIDE
            </button>
          </div>
        )}
        imgarr={[Infoimg1]}
      />
    </div>
  );
};
