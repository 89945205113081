import React, { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Avatar from "../Container/Avatar/Avatar";
import Experience from "../Container/Experience/Experience";
import LandingPage from "../Container/LandingPage/LandingPage";

function CustomRoutes() {
  const [auth, setauth] = useState(false);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage setauth={setauth} />} />
        <Route path="/avatar" element={<Avatar auth={auth} />} />
        <Route path="/experience" element={<Experience auth={auth} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default CustomRoutes;
