import * as BABYLON from "babylonjs";
import { io, Manager } from "socket.io-client";
import "babylonjs-loaders";
import { store } from "../../../Redux/redux";
import {
  addNamePlate,
  localPlayer,
  setCharacter,
  spawnLocalPlayer,
} from "../Player";
import {
  ADD_USER_INFO,
  UPDATE_USER_COUNT,
} from "../../../Redux/actions/userDataAction";

const EndPoint = "https://iaf.metaqube.ai";

export const socket = io(EndPoint, {
  transports: ["websocket"],
  upgrade: false,
});

export let AllUsers = [];
let currentScene = null;

export const spawnRemotePlayer = async (userId, name, scene, avatarConfig) => {
  let gender = null;
  if (avatarConfig.Gender === 0) {
    gender = "Male_IAF.glb";
  } else {
    gender = "Female_IAF.glb";
  }

  BABYLON.SceneLoader.ImportMeshAsync("", "/", gender, scene, null).then(
    (loadedMesh) => {
      const remotePlayer = loadedMesh.meshes[0];
      remotePlayer.position = new BABYLON.Vector3(
        -0.1 + generateRandomPosition(-4, 4),
        0,
        19.4 + generateRandomPosition(-4, 4)
      );
      remotePlayer.name = userId;

      remotePlayer.rotation = new BABYLON.Vector3(0, Math.PI, 0);

      //animations
      remotePlayer.animations.push(...loadedMesh.animationGroups);
      remotePlayer.animations[0].play(true);
      remotePlayer.scaling = new BABYLON.Vector3(0.17, 0.17, -0.17);

      //nameplate
      addNamePlate(name, remotePlayer, scene, false);

      //avatarConfig
      setCharacter(remotePlayer, avatarConfig);

      //anim
      remotePlayer.onAfterWorldMatrixUpdateObservable.add(() => {
        remotePlayer.animations[1].play(false);
      });

      return remotePlayer;
    }
  );
};

export const joinRoom = (scene, avatarConfig, room, name) => {
  currentScene = scene;
  let sId = socket.id;
  const user_Name = name;
  const room_ID = room;
  currentScene = scene;

  store.dispatch(
    ADD_USER_INFO({ ...store.getState().UserReducer.userData, sId: sId })
  );

  socket.on("pingCurrentPos", () => {
    const user = scene.getMeshByName(sId);
    if (user) {
      const newPosition = {
        x: user.position.x,
        y: user.position.y,
        z: user.position.z,
      };
      const newRotation = user.rotation.y;
      socket.emit("pos", {
        room: room_ID,
        pos: newPosition,
        rot: newRotation,
      });
    }
  });

  socket.emit(
    "join",
    {
      name: user_Name,
      room: room_ID,
      avatarConfig: avatarConfig,
    },
    ({ responseCode, users }) => {
      AllUsers = users;

      store.dispatch(UPDATE_USER_COUNT(AllUsers.length));

      AllUsers.forEach((element) => {
        if (element.sId !== sId) {
          spawnRemotePlayer(
            element.sId,
            element.name,
            scene,
            element.avatarConfig
          ).then((remotePlayer) => {
            remotePlayer.position = new BABYLON.Vector3(
              -0.1 + generateRandomPosition(-4, 4),
              0,
              30 + generateRandomPosition(-4, 4)
            );
            socket.emit("pingCurrentPos", { room_ID: room_ID });
          });
        }
      });

      const user = scene.getMeshByName(sId);

      if (user) {
        user.onAfterWorldMatrixUpdateObservable.add(() => {
          const newPosition = {
            x: user.position.x,
            y: user.position.y,
            z: user.position.z,
          };
          const newRotation = user.rotation.y;
          socket.emit("pos", {
            room: room_ID,
            pos: newPosition,
            rot: newRotation,
          });
        });
      }
    }
  );

  socket.on("emoteUsed", ({ sId, animName }) => {
    console.log("remote user playing anim", animName);
    const mesh = scene.getMeshByName(sId);
    if (mesh) {
      for (let index = 0; index < mesh.animations.length; index++) {
        const element = mesh.animations[index];
        if (element.name === animName) {
          element.play(false);
          element.onAnimationGroupEndObservable.add(() => {
            mesh.animations[0].play(true);
          });
        } else {
          element.stop();
        }
      }
    }
  });

  socket.on("newuserjoined", ({ sId, name, avatarConfig }) => {
    AllUsers.push({ sId: sId, name: name, avatarConfig: avatarConfig });
    // window.enqueueSnackbar(`${name} Joined !`, {
    //   variant: "info",
    //   autoHideDuration: 2000,
    // });

    store.dispatch(UPDATE_USER_COUNT(AllUsers.length));
    spawnRemotePlayer(sId, name, scene, avatarConfig).then((remotePlayer) => {
      remotePlayer.position = new BABYLON.Vector3(
        -0.1 + generateRandomPosition(-1, 1),
        0,
        30 + generateRandomPosition(-2, 2)
      );
    });
  });

  socket.on("userleft", ({ sId }) => {
    try {
      scene.getMeshByName(sId).dispose();
      const arr = AllUsers.filter((e) => e.sId != sId);
      AllUsers = arr;
      store.dispatch(UPDATE_USER_COUNT(arr.length));
    } catch (err) {
      console.log("user does not exist");
    }
  });

  socket.on("rem-pos", ({ sId, pos, rot }) => {
    const mesh = scene.getMeshByName(sId);
    if (mesh) {
      mesh.position = new BABYLON.Vector3(pos.x, pos.y - 1.2, pos.z);
      mesh.rotation = new BABYLON.Vector3(
        mesh.rotation.x,
        rot,
        mesh.rotation.z
      );

      if (store.getState().UserReducer.BMWZone) {
        mesh.setEnabled(false);
      } else {
        mesh.setEnabled(true);
      }

      // mesh.animations[5].play(false);
    }
  });
};

const generateRandomPosition = (min, max) => {
  return Math.random() * (max - min) + min;
};

export const onAnimPlayed = (animName) => {
  socket.emit("emote", {
    userId: socket.id,
    room: "MainRoom",
    animName: animName,
  });
};

export const localUserLeave = async (sId, roomID) => {
  socket.emit("leaveroom");
};
