import * as BABYLON from "babylonjs";
import { stopNavigation } from "./Navigation";

import * as GUI from "babylonjs-gui";
import { store } from "../../Redux/redux";

var inputMap = {};

export let importedanimationGroups = [];

export let localPlayer;

let runningAnim;
let runningBackwardsAnim;

export const spawnLocalPlayer = async (userid, camera, scene) => {
  const avatarConfig = store.getState().AvatarReducer;
  let gender = null;
  if (avatarConfig.Gender === 0) {
    gender = "Male_IAF.glb";
  } else {
    gender = "Female_IAF.glb";
  }

  await BABYLON.SceneLoader.ImportMeshAsync("", "/", gender, scene, null).then(
    (loadedMesh) => {
      localPlayer = loadedMesh.meshes[0];
      importedanimationGroups = loadedMesh.animationGroups;
      localPlayer.animations.push(...importedanimationGroups);
      localPlayer.animations[0].play(true);
      setCharacter(localPlayer, avatarConfig);

      localPlayer.scaling = new BABYLON.Vector3(0.17, 0.17, -0.17);
      localPlayer.name = "me";

      runningAnim = localPlayer.animations.find(
        (anim) => anim.name === "Running"
      );

      runningBackwardsAnim = localPlayer.animations.find(
        (anim) => anim.name === "Running Backward"
      );

      addNamePlate("Me", localPlayer, scene, true);
      localPlayer.rotation = new BABYLON.Vector3.Zero();
      const colliderMesh = BABYLON.MeshBuilder.CreateCapsule(userid, {}, scene);
      colliderMesh.checkCollisions = true;
      colliderMesh.isVisible = false;
      colliderMesh.position = new BABYLON.Vector3(0, 1.3, 30);
      colliderMesh.scaling = new BABYLON.Vector3(2, 2.5, 2);
      colliderMesh.addChild(localPlayer);

      localPlayer.position = new BABYLON.Vector3(0, -0.48, 0);

      const children = localPlayer.getChildTransformNodes();
      children.forEach((headRef) => {
        if (headRef.name === "mixamorig:HeadTop_End") {
          camera.lockedTarget = headRef;
        }
      });
    }
  );
};

export const addNamePlate = (name, parent, scene, isLocalUser) => {
  var groundWidth = 1;
  var groundHeight = 1;

  var namePlate = BABYLON.MeshBuilder.CreatePlane(
    "namePlate",
    {
      width: groundWidth,
      height: groundHeight,
      sideOrientation: BABYLON.Mesh.DOUBLESIDE,
    },
    scene
  );
  namePlate.isPickable = false;

  //Adding VideoCircle
  const videoDisc = BABYLON.MeshBuilder.CreateDisc(
    "videoPlane",
    { radius: 50 },
    scene
  );

  const videoDiscMat = new BABYLON.StandardMaterial("video", scene);
  videoDisc.material = videoDiscMat;

  videoDiscMat.diffuseColor = BABYLON.Color3.Black();

  const children = parent.getChildTransformNodes();
  children.forEach((headRef) => {
    if (headRef.name === "mixamorig:HeadTop_End") {
      namePlate.setParent(headRef);
      videoDisc.setParent(headRef);
    }
  });

  videoDisc.position = new BABYLON.Vector3(0, -720, 0);
  videoDisc.rotation = BABYLON.Vector3.Zero();
  videoDisc.scaling = new BABYLON.Vector3(8, -8, 8);
  videoDisc.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
  videoDisc.setEnabled(false);

  namePlate.position = new BABYLON.Vector3(0, -150, 0);
  namePlate.rotation = new BABYLON.Vector3(0, Math.PI, Math.PI);
  namePlate.scaling = new BABYLON.Vector3(800, 300, 400);

  //Create dynamic texture
  var advanceTexture = new GUI.AdvancedDynamicTexture.CreateForMesh(
    namePlate,
    400,
    150,
    false,
    null,
    true
  );

  var panel = new GUI.Rectangle("name");
  panel.background = "#FFFFFF";
  panel.color = "#FFFFFF";
  panel.cornerRadius = 10;
  panel.height = "60%";
  panel.width = "78%";
  panel.clipChildren = false;
  panel.thickness = 4;
  panel.verticalAlignment = 0;
  panel.horizontalAlignment = 0;
  if (isLocalUser) {
    panel.horizontalAlignment = 2;
  }

  advanceTexture.addControl(panel);
  var textBlock = new GUI.TextBlock("name", name);
  textBlock.resizeToFit = true;
  textBlock.fontSize = "45px";
  textBlock.color = "#2B528A";
  textBlock.textWrapping = 2;
  textBlock.width = "90%";
  panel.addControl(textBlock);

  // const muteEllipse = new GUI.Ellipse("MicStatus");
  // if (isLocalUser) {
  //   muteEllipse.isVisible = false;
  // }
  // muteEllipse.width = "80px";
  // muteEllipse.height = "80px";
  // muteEllipse.horizontalAlignment = 1;
  // muteEllipse.verticalAlignment = 0;
  // muteEllipse.top = "7px";
  // muteEllipse.color = "#FFFFFF";
  // muteEllipse.thickness = 4;
  // advanceTexture.addControl(muteEllipse);

  // const muteImg = new GUI.Image("mute", muteButImg);
  // muteImg.width = "100%";
  // muteImg.height = "100%";
  // muteEllipse.addControl(muteImg);

  namePlate.billboardMode = BABYLON.Mesh.BILLBOARDMODE_ALL;
};

export const keyboardEvents = (scene) => {
  scene.actionManager = new BABYLON.ActionManager(scene);
  scene.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnKeyDownTrigger,
      function (evt) {
        inputMap[evt.sourceEvent.key] = evt.sourceEvent.type == "keydown";
      }
    )
  );
  scene.actionManager.registerAction(
    new BABYLON.ExecuteCodeAction(
      BABYLON.ActionManager.OnKeyUpTrigger,
      function (evt) {
        inputMap[evt.sourceEvent.key] = "";
        if (evt.sourceEvent.key === "w" || evt.sourceEvent.key == "W") {
          runningAnim.stop();
        }
        if (evt.sourceEvent.key == "s" || evt.sourceEvent.key == "S") {
          runningBackwardsAnim.stop();
        }
      }
    )
  );
};

export const setCharacter = (rootMesh, avatarConfig) => {
  const meshes = rootMesh._children[0].getChildren();
  meshes.forEach((ch_mesh) => {
    switch (ch_mesh.name.split("_")[0]) {
      case "Hair":
        ch_mesh.setEnabled(false);
        if (ch_mesh.name.split("_")[1] == avatarConfig.Hair) {
          ch_mesh.setEnabled(true);
        }
        break;
      case "Top":
        ch_mesh.setEnabled(false);
        if (ch_mesh.name.split("_")[1] == avatarConfig.Top) {
          ch_mesh.setEnabled(true);
        }
        break;
      case "Bottom":
        ch_mesh.setEnabled(false);
        if (ch_mesh.name.split("_")[1] == avatarConfig.Bottom) {
          ch_mesh.setEnabled(true);
        }
        break;
      case "Eyeglass":
        ch_mesh.setEnabled(false);
        if (ch_mesh.name.split("_")[1] == avatarConfig.Eyeglass) {
          ch_mesh.setEnabled(true);
        }
        break;
      case "Beard":
        ch_mesh.setEnabled(false);
        if (ch_mesh.name.split("_")[1] == avatarConfig.Beard) {
          ch_mesh.setEnabled(true);
        }
        break;

      case "body":
        ch_mesh.material.albedoColor = BABYLON.Color3.FromHexString(
          avatarConfig.skinColor
        );
    }
  });
};

export const inputController = (camera, scene) => {
  var cameraForwardRayPosition = camera.getForwardRay().direction;
  var cameraForwardRayPositionWithoutY = new BABYLON.Vector3(
    -cameraForwardRayPosition.x,
    0,
    -cameraForwardRayPosition.z
  );
  if (inputMap["w"] || inputMap["W"]) {
    stopNavigation();
    localPlayer.parent.lookAt(
      localPlayer.parent.position.add(cameraForwardRayPositionWithoutY),
      0,
      0,
      0
    );
    localPlayer.parent.moveWithCollisions(
      new BABYLON.Vector3(
        cameraForwardRayPosition.x * 0.02 * scene.getEngine().getDeltaTime(),
        0,
        cameraForwardRayPosition.z * 0.02 * scene.getEngine().getDeltaTime()
      )
    );

    runningAnim.play(false);
  }
  if (inputMap["s"] || inputMap["S"]) {
    localPlayer.parent.moveWithCollisions(
      localPlayer.forward.scaleInPlace(-0.15)
    );

    runningBackwardsAnim.play(false);
  }
};
