import React, { useEffect, useState } from "react";
import "./ExperienceUI.css";
import RightArrow from "./Assets/Right.png";
import LefttArrow from "./Assets/Left.png";
import About from "./Assets/About.png";
import Exit from "./Assets/Exit.png";
import Light from "./Assets/Light.png";

//Explore Images
import Studio from "./Assets/Studio.png";
import LeftHall from "./Assets/Left Hall.png";
import CentralHall from "./Assets/Central Hall.png";
import RightHall from "./Assets/Right Hall.png";
import NarayanSinha from "./Assets/Narayan Sinha.png";
import Aravani from "./Assets/Aravani.png";
import Auditorium from "./Assets/Auditorium.png";
import KochiBiennale from "./Assets/Kochi Biennale.png";
import Serendipity from "./Assets/Serendipity.png";
import InfoDesk1 from "./Assets/Info Desk 1.png";
import LastImage from "./Assets/IMG_0927 2.png";
import DialogBox from "../../../Components/DialogBox/DialogBox";
import LeftArrowBlack from "./Assets/leftArrowBlack.png";

import {
  AravaniContent,
  AuditoriumContent,
  BMWContent,
  CentralHallContent,
  EngulfContent,
  IndianArtFareContent,
  InfoContent,
  KochiContent,
  LeftHallContent,
  RightHallContent,
  SerendipityContent,
  StudioContent,
} from "./Content/Content";
import { landingUrl } from "../../../Constants/constants";
import Tutorial from "./UI_Components/Tutorial/Tutorial";
import { List, ListItem } from "@mui/material";
import { showArialView, teleportToHotspot } from "../ArialView";
import { store } from "../../../Redux/redux";
import { UPDATE_ZONE } from "../../../Redux/actions/userDataAction";
import { useSelector } from "react-redux";

export const arr = [
  {
    name: "THE STUDIO",
    img: Studio,
    btnName: "ENTER THE STUDIO AND WORKSHOP SPACE",
    Mname: "THE STUDIO",
    playerX: 32.2,
    playerY: 1.3,
    playerZ: -177.174,
    rx: 0,
    ry: (Math.PI / 2) * 3,
    rz: 0,
    alpha: 3.199,
    beta: 1.418,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <StudioContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "LEFT HALL",
    img: LeftHall,
    btnName: "ENTER LEFT HALL",
    Mname: "LEFT HALL",
    playerX: -18.655,
    playerY: 1.3,
    playerZ: -187.894,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.397,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <LeftHallContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "CENTRAL HALL",
    img: CentralHall,
    Mname: "CENTRAL HALL",
    btnName: "ENTER CENTRAL HALL",
    playerX: -19.37,
    playerY: 1.3,
    playerZ: -99.694,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.347,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <CentralHallContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "RIGHT HALL",
    img: RightHall,
    Mname: "RIGHT HALL",
    btnName: "ENTER RIGHT HALL",
    playerX: -18.348,
    playerY: 1.3,
    playerZ: -8.641,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.317,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <RightHallContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "<p><i>ENGULF</i><br>BY<br>NARAYAN SINHA</p>",
    img: NarayanSinha,
    Mname: "<i>ENGULF</i> by NARAYAN SINHA",
    btnName: "ENGULF by NARAYAN SINHA",
    playerX: -24.0,
    playerY: 1.3,
    playerZ: 7.539,
    rx: 0,
    ry: Math.PI,
    rz: 0,
    alpha: -1.511,
    beta: 1.31,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <EngulfContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "<p><i>THE FUTURE IS FEMME</i><br>by<br>ARAVANI ART PROJECT</p>",
    img: Aravani,
    Mname: "<i>THE FUTURE IS FEMME</i> BY ARAVANI ART PROJECT",
    btnName: "THE FUTURE IS FEMME BY ARAVANI ART PROJECT",
    playerX: -0.228,
    playerY: 1.3,
    playerZ: 283.085,
    rx: 0,
    ry: 0,
    rz: 0,
    alpha: 0.948,
    beta: 1.389,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <AravaniContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "THE AUDITORIUM",
    img: Auditorium,
    Mname: "THE AUDITORIUM",
    btnName: "AUDITORIUM",
    playerX: 51.367,
    playerY: 1.3,
    playerZ: -104.151,
    rx: 0,
    ry: (Math.PI / 2) * 3,
    rz: 0,
    alpha: 3.21,
    beta: 1.356,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <AuditoriumContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "<p><i>KOCHI REWIND</i> BY<br>KOCHI BIENNALE<br>FOUNDATION</p>",
    img: KochiBiennale,
    Mname: "<i>KOCHI REWIND</i> by KOCHI BIENNALE FOUNDATION",
    btnName: "KOCHI REWIND BY KOCHI BIENNALE FOUNDATION",
    playerX: 22.994,
    playerY: 1.3,
    playerZ: 42.512,
    rx: 0,
    ry: 0,
    rz: 0,
    alpha: 1.565,
    beta: 1.389,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <KochiContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "<p><i>RESET</i> BY<br>SERENDIPITY ARTS</p>",
    img: Serendipity,
    Mname: "<i>RESET</i> by SERENDIPITY ARTS",
    btnName: "RESET BY SERENDIPITY ARTS",
    playerX: 6.226,
    playerY: 1.3,
    playerZ: -133.224,
    rx: 0,
    ry: (Math.PI / 2) * 3,
    rz: 0,
    alpha: 3.159,
    beta: 1.495,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <SerendipityContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "INFORMATION DESK",
    img: InfoDesk1,
    Mname: "INFORMATION DESK",
    btnName: "INFORMATION DESK",
    playerX: -6.193,
    playerY: 1.3,
    playerZ: -60.485,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0.095,
    beta: 1.216,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <InfoContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
  {
    name: "<p>INDIA ART FAIR FACADE<br>BY<br>ANSHUKA MAHAPATRA</p>",
    img: LastImage,
    Mname: "INDIA ART FAIR FACADE BY ANSHUKA MAHAPATRA",
    btnName: "INDIA ART FAIR FACADE BY ANSHUKA MAHAPATRA",
    playerX: 13.553,
    playerY: 1.3,
    playerZ: -86.695,
    rx: 0,
    ry: Math.PI / 2,
    rz: 0,
    alpha: 0,
    beta: 1.446,
    onClick: (setopenModal, setModalContent) => {
      setopenModal(true);
      setModalContent(
        <IndianArtFareContent
          onCloseClick={() => {
            setopenModal(false);
          }}
        />
      );
    },
  },
];

function ExperienceUI({
  isloading,
  setopenModal,
  openModal,
  modalContent,
  setModalContent,
  setcolor,
  setModalfullScreen,
  color,
  modalfullScreeen,
}) {
  const [openExplore, setopenExplore] = useState(false);
  const [maxWidth, setmaxWidth] = useState("md");
  const zoneData = useSelector((state) => state.UserReducer.BMWZone);

  useEffect(() => {
    if (!isloading) {
      setopenModal(true);
    }
  }, [isloading]);
  const [selected, setselected] = useState(0);
  return (
    <div className="experienceUI_root">
      <div
        className="experienceUI_nav"
        style={{
          flexDirection: "column",
          zIndex: 1,
          position: "absolute",
          width: "100%",
        }}
      >
        <div className="nav_Holder">
          <text
            onClick={() => {
              setopenExplore(!openExplore);
              setselected(0);
            }}
            style={{
              borderBottom:
                openExplore && selected == 0 ? "4px solid white" : "none",
            }}
          >
            Explore
          </text>
          <text
            onClick={() => {
              window.open("https://indiaartfair.in/");
              setselected(1);
            }}
            style={{ borderBottom: selected == 1 ? "4px solid white" : "none" }}
          >
            About
          </text>
          <text
            onClick={() => {
              setselected(2);
              window.open(
                "https://in.bookmyshow.com/special/india-art-fair/ET00318408"
              );
            }}
            style={{ borderBottom: selected == 2 ? "4px solid white" : "none" }}
          >
            Get Tickets
          </text>
        </div>
        <div
          className={`Explore_Root ${
            openExplore ? "increaseHeight" : "decreaseHeight"
          }`}
        >
          <div className="nav_PC">
            <div>
              <img
                src={LefttArrow}
                style={{ marginLeft: "1em", cursor: "pointer" }}
                onClick={() => {
                  const element = document.getElementById(
                    "Explore_Container_ref"
                  );
                  element.scrollLeft -= 400;
                }}
              />
            </div>
            <div id="Explore_Container_ref" className="Explore_Container">
              {arr.map((e) => (
                <div
                  className="Explore_Container_Card"
                  onClick={() => {
                    if (store.getState().UserReducer.BMWZone) {
                      store.dispatch(UPDATE_ZONE(false));
                      window.canmove = true;
                    }
                    teleportToHotspot(
                      e.playerX,
                      e.playerY,
                      e.playerZ,
                      e.rx,
                      e.ry,
                      e.rz,
                      e.alpha,
                      e.beta
                    );
                    setopenExplore(false);
                  }}
                >
                  <div
                    className="hover_Container"
                    dangerouslySetInnerHTML={{ __html: e.name }}
                  ></div>
                  <img src={e.img} style={{ height: "100%", width: "100%" }} />
                </div>
              ))}
            </div>
            <div>
              <img
                src={RightArrow}
                style={{ marginRight: "1em", cursor: "pointer" }}
                onClick={() => {
                  const element = document.getElementById(
                    "Explore_Container_ref"
                  );
                  element.scrollLeft += 400;
                }}
              />
            </div>
          </div>
          <div className="nav_Mobile">
            <List>
              {arr.map((e) => (
                <ListItem
                  button={true}
                  onClick={() => {
                    if (store.getState().UserReducer.BMWZone) {
                      store.dispatch(UPDATE_ZONE(false));
                      window.canmove = true;
                    }
                    teleportToHotspot(
                      e.playerX,
                      e.playerY,
                      e.playerZ,
                      e.rx,
                      e.ry,
                      e.rz,
                      e.alpha,
                      e.beta
                    );
                    setopenExplore(false);
                  }}
                >
                  <text dangerouslySetInnerHTML={{ __html: e.Mname }}></text>
                </ListItem>
              ))}
            </List>
          </div>
        </div>
      </div>
      {zoneData && (
        <button
          style={{
            position: "fixed",
            paddingBlock: "0.8em",
            paddingInline: "1em",
            marginTop: "5em",
            marginLeft: "1em",
            borderRadius: "1em",
            border: "none",
            backgroundColor: "#757575",
            cursor: "pointer",
          }}
          onClick={() => {
            const e = arr[1];
            store.dispatch(UPDATE_ZONE(false));
            window.canmove = true;
            teleportToHotspot(
              e.playerX,
              e.playerY,
              e.playerZ,
              e.rx,
              e.ry,
              e.rz,
              e.alpha,
              e.beta
            );
          }}
        >
          <img src={LeftArrowBlack} />
        </button>
      )}
      <div className="experienceUI_nav experienceUI_footer">
        <text style={{ marginLeft: "1em" }}>
          Copyright © 2022 India Art Fair
        </text>
        <div
          style={{
            display: "flex",
            marginRight: "1em",
            gap: "1em",
            paddingBlock: "0.5em",
          }}
        >
          <img
            style={{ cursor: "pointer" }}
            src={Light}
            onClick={() => {
              showArialView();
              window.canmove = true;
            }}
          />
          <img
            style={{ cursor: "pointer" }}
            src={About}
            onClick={() => {
              setopenModal(true);
              setmaxWidth("md");
              setModalfullScreen(false);
              setcolor("#53B0AE");
              setModalContent(
                <Tutorial
                  onClose={() => {
                    setopenModal(false);
                  }}
                />
              );
            }}
          />
          <img
            style={{ cursor: "pointer" }}
            src={Exit}
            onClick={() => {
              window.location.href = "/";
            }}
          />
        </div>
      </div>
      <DialogBox
        backgroundColor={color}
        open={openModal}
        Content={modalContent}
        fullScreen={modalfullScreeen}
        maxWidth={maxWidth}
      />
    </div>
  );
}

export default ExperienceUI;
