import React, { useState } from "react";
import "./LandingPage.css";
import XRCLogo from "./Assets/XRCLogo.png";
import IAFLogo from "./Assets/IAFLogo.png";
import MetaQubeLogo from "./Assets/MetaQubeLogo.png";
import DialogBox from "../../Components/DialogBox/DialogBox";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { store } from "../../Redux/redux";
import { ADD_USER_INFO } from "../../Redux/actions/userDataAction";

const Form = ({ setopenModal, setauth }) => {
  const navigate = useNavigate();
  const [name, setname] = useState("");
  return (
    <div className="form_root">
      <IconButton
        className="close_BTN"
        onClick={() => {
          setopenModal(false);
        }}
      >
        <CloseIcon />
      </IconButton>
      <div className="form_Holder">
        <label>Full Name</label>
        <input
          placeholder="Enter your Name"
          onChange={(e) => {
            setname(e.target.value);
          }}
        />
      </div>
      <button
        className="black_btn"
        disabled={name == ""}
        onClick={() => {
          store.dispatch(ADD_USER_INFO({ Name: name }));
          setauth(true);
          navigate("/avatar");
        }}
      >
        Enter Metaverse
      </button>
    </div>
  );
};

function LandingPage({ setauth }) {
  const [openModal, setopenModal] = useState(false);
  return (
    <>
      <div className="landing_root">
        <div className="landing_nav">
          <img src={XRCLogo} />
          <img src={IAFLogo} />
        </div>
        <div className="landing_center">
          <text className="firstheading">Welcome to the</text>
          <text className="heading">India Art Fair 2022 Metaverse</text>
          <button
            className="BTN custom_BTN"
            style={{ marginTop: "1em" }}
            onClick={() => {
              setopenModal(true);
            }}
          >
            LET’S BEGIN
          </button>
        </div>
        <div className="landing_Bottom">
          <img src={MetaQubeLogo} />
        </div>
      </div>
      <DialogBox
        open={openModal}
        maxWidth={"xs"}
        Content={<Form setopenModal={setopenModal} setauth={setauth} />}
        backgroundColor={"white"}
      />
    </>
  );
}

export default LandingPage;
