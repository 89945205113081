import React, { useEffect, useState } from "react";
import "./ImageViewer.css";
import { Divider, IconButton } from "@mui/material";
import selectedImage from "./Assets/selectedImage.png";
import UnselectedImage from "./Assets/UnselectedImage.png";
import leftButton from "./Assets/arrow-left.png";
import rightButton from "./Assets/arrow-right.png";
import CloseIcon from "@mui/icons-material/Close";

function ImageViewer({ onCloseClick, sideContent, imgarr }) {
  const [selectedImg, setSelectedImg] = useState(0);
  const [imgArr, setimgArr] = useState([]);
  useEffect(() => {
    setSelectedImg(0);
    setimgArr(imgarr);
  }, []);
  return (
    <div className="viewer_root">
      <IconButton
        style={{ position: "absolute", top: 0, right: 0 }}
        onClick={onCloseClick}
      >
        <CloseIcon style={{ color: "black" }} />
      </IconButton>
      <div className="image_Viewer">
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img className="Main_Image" src={imgArr[selectedImg]} />
        </div>
        <div className="carousel">
          <img
            style={{ width: "auto", cursor: "pointer" }}
            src={leftButton}
            onClick={() => {
              let index = selectedImg;
              setSelectedImg(index);
              if (index - 1 >= 0) {
                index--;
                setSelectedImg(index);
              } else {
                setSelectedImg(imgArr.length - 1);
              }
            }}
          />
          {imgArr.map((e, index) => (
            <img
              key={index}
              style={{ width: "auto" }}
              src={selectedImg == index ? selectedImage : UnselectedImage}
            />
          ))}
          <img
            style={{ width: "auto", cursor: "pointer" }}
            src={rightButton}
            onClick={() => {
              let index = selectedImg;
              if (index + 1 < imgArr.length) {
                index++;
                setSelectedImg(index);
              } else {
                setSelectedImg(0);
              }
            }}
          />
        </div>
      </div>
      <Divider
        sx={{ borderRightWidth: 2, bgcolor: "#5A5A5A" }}
        orientation="vertical"
        flexItem={true}
        variant="middle"
        className="Divider_Class"
      />
      <div className="viewer_content">{sideContent()}</div>
    </div>
  );
}

export default ImageViewer;
