export const landingUrl = "https://metaverse.indiaartfair.in/";
export const redirecttoLandingPage = (auth) => {
  if (!auth) {
    window.location.href = "/";
    return;
  }
};
export const FadeElement = (id, time) => {
  const root = document.getElementById(id);
  root.style.opacity = 1;
  const interval = setInterval(() => {
    if (root.style.opacity > 0) {
      root.style.opacity -= 0.01;
    } else {
      clearInterval(interval);
      root.remove();
    }
  }, time);
};
